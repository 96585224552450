import Vue from 'vue'
import VueRouter from 'vue-router'
// 个人中心
const Setting = resolve => require.ensure([], () => resolve(require('../views/Setting/Setting')), "setting")
const ModifyInfo = resolve => require.ensure([], () => resolve(require('../views/Setting/Widgets/ModifyInfo')), "setting")
const ModifyPwd = resolve => require.ensure([], () => resolve(require('../views/Setting/Widgets/ModifyPwd')), "setting")
const ClassManagement = resolve => require.ensure([], () => resolve(require('../views/Setting/Widgets/ClassManagement')), "setting")
const TeachQualification = resolve => require.ensure([], () => resolve(require('../views/Setting/Widgets/TeachQualification')), "setting")
const MyOrganization = resolve => require.ensure([], () => resolve(require('../views/Setting/Widgets/MyOrganization')), "setting")
// 关联账号
const RelationAccount = resolve => require.ensure([], () => resolve(require('../views/Setting/Widgets/RelationAccount')), 'setting')
// 统一登录
const SignIn = resolve => require.ensure([], () => resolve(require('../views/SignIn/SignIn')), "signin")
const LenovoModuleSelect = resolve => require.ensure([], () => resolve(require('../views/SignIn/LenovoModuleSelect')), "lenovo.moduleselect")
const LenovoDownload = resolve => require.ensure([], () => resolve(require('../views/SignIn/LenovoDownload')), "lenovo.download")
const LenovoAbout = resolve => require.ensure([], () => resolve(require('../views/About/LenovoAbout')), "lenovo.about")
Vue.use(VueRouter)

// 账号关联
const AccountRelation = resolve => require.ensure([], () => resolve(require('../views/AccountRelation/Index')), 'relation.index')

// 第三方相关
const CloudDiskIndex = resolve => require.ensure([], () => resolve(require('../views/ThirdParty/CloudDisk/Index')), 'third.index')
const MarketClient = resolve => require.ensure([], () => resolve(require('../views/ThirdParty/CloudDisk/MarketClient')), 'third.market')

// 中教云跳转登录
const NecibookSignIn = resolve => require.ensure([], () => resolve(require('../views/ThirdParty/Necibook/Index')), 'third.market')

// 中教云解析code获取session部分
const NecibookAuth = resolve => require.ensure([], () => resolve(require('../views/ThirdParty/Necibook/Auth')), 'third.market')
// 获取中教云页面地址解析中转页
const NecibookTransit = resolve => require.ensure([], () => resolve(require('../views/ThirdParty/Necibook/Transit')), 'third.market')
// 宁教云跳转登录
const NxeDuYunSignIn = resolve => require.ensure([], () => resolve(require('../views/ThirdParty/NxeDuYun/Index')), 'third.market')
// 宁教云解析code获取session部分
const NxeDuYunAuth = resolve => require.ensure([], () => resolve(require('../views/ThirdParty/NxeDuYun/Auth')), 'third.market')
// 宁教云跳转登录
const NxeDuYunZznSignIn = resolve => require.ensure([], () => resolve(require('../views/ThirdParty/NxeDuYun/ZznIndex')), 'third.market')
// 宁教云解析code获取session部分
const NxeDuYunZznAuth = resolve => require.ensure([], () => resolve(require('../views/ThirdParty/NxeDuYun/ZznAuth')), 'third.market')
// 中转页
const GoToView = resolve => require.ensure([], () => resolve(require('../views/Home/GoToView.vue')), 'home.gotoview')

const routes = [
  {
    path: '/',
    redirect: "/Account/SignIn"
  },
  {
    path: '/Account/Setting',
    name: '个人中心',
    component: Setting,
    meta:{ title:'个人中心'},
    redirect: "/Account/ModifyInfo",
    children:[
      {
        path:'/Account/ModifyInfo',
        component:ModifyInfo,
        name:'修改资料',
        meta:{ title:'个人中心'},
      },
      {
        path:'/Account/ModifyPwd',
        component:ModifyPwd,
        name:'修改密码',
        meta:{ title:'个人中心'},
      },
      {
        path:'/Account/ClassManagement',
        component:ClassManagement,
        name:'班级管理',
        meta:{ title:'个人中心'},
      },
      {
        path:'/Account/TeachQualification',
        component:TeachQualification,
        name:'任教资历',
        meta:{ title:'个人中心'},
      },
      {
        path:'/Account/Organization',
        component: MyOrganization,
        name:'我的组织',
        meta:{ title:'个人中心'},
      },
      {
        path:'/Account/Relation',
        component:RelationAccount,
        name:'关联备授课账号',
        meta:{ title:'个人中心'},
      }
  ]
  },
  {
    path: '/Account/SignIn',
    name: 'unifiedLogin',
    component: SignIn
  },
  {
    path: '/Account/GoToView',
    name: '中转',
    component: GoToView
  },
  {
    path: '/Account/LenovoModuleSelect',
    name: '联想选择页',
    component: LenovoModuleSelect,
    meta:{ title:'联想教学教研'},
  },
  {
    path: '/Intelligence/installer',
    name: '联想下载页',
    component: LenovoDownload,
    meta:{ title:'联想教学教研'},
  },
  {
    path:'/Account/LenovoAbout',
    component:LenovoAbout,
    name:'联想关于页',
    meta:{ title:'联想教学教研'},
  },
  /**
   * @params
   * 账号关联
   */
  {
    path: '/Account/Relation',
    name: '账号关联',
    component: AccountRelation,
    meta: { title: '账号关联' }
  },
  /**
   * @params
   * 第三方相关
   */
  {
    path: '/CloudDisk/SignIn',
    name: '云碟跳转',
    component: CloudDiskIndex,
    meta: { title: '云碟' }
  },
  {
    path: '/CloudDisk/Market',
    name: 'cloudDiskMarket',
    component: MarketClient,
    meta: { title: '云碟' }
  },
  {
    path: '/Necibook/SignIn',
    name: '中教云跳转登录页',
    component: NecibookSignIn,
    meta: { title: '中教云' }
  },
  {
    path: '/Necibook/Auth',
    name: '中教云回调页面',
    component: NecibookAuth,
    meta: { title: '中教云' }
  },
  {
    path: '/Necibook/Transit',
    name: '中教云回调页面',
    component: NecibookTransit,
    meta: { title: '中教云' }
  },
  {
    path: '/NxeDuYun/SignIn',
    name: '宁教云跳转登录页',
    component: NxeDuYunSignIn,
    meta: { title: '宁教云' }
  },
  {
    path: '/NxeDuYun/Auth',
    name: '宁教云回调页面',
    component: NxeDuYunAuth,
    meta: { title: '宁教云' }
  },
  {
    path: '/NxeDuYun/ZznSignIn',
    name: '宁教云小程序跳转登录页',
    component: NxeDuYunZznSignIn,
    meta: { title: '宁教云' }
  },
  {
    path: '/NxeDuYun/ZznAuth',
    name: '宁教云小程序回调页面',
    component: NxeDuYunZznAuth,
    meta: { title: '宁教云' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: "/",
  routes
})

export default router
