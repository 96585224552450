import { get, post, postJSON, postMultiData } from './http'
import Vue from 'vue'

// 登出
export const LOGOUT = (params) => post('AccountService.asmx/LogOut', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'accountWs')

// 平台信息
export const GET_PLATFORM_SYSTEMHOST = (params) => get('PaasService.asmx/GetPlatformSystemHost', params, {"Content-Type": 'application/x-www-form-urlencoded'}, 'accountWs')

// 获取 session 选定角色
export const GETACCOUNTBYSESSION = (params) => post('AccountService.asmx/GetAccountBySession', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'accountWs')

// 获取腾讯云签名
export const FEDERATIONTOKENBYBUCKET = (params) => get('PublicToolSCenter/api/tencent_cloud/FederationTokenByBucket', params, null, 'webapi')

// 获取角色配置
export const GETROLESTRUCTURE = (params) => post('ConsoleService_Region.asmx/GetRoleStructure', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'accountWs')

// 获取用户所有角色
export const GETACCOUNTROLE = (params) => post('AccountService.asmx/GetAccountRole', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'accountWs')

// 模块使用记录
export const SETROLEISEMODULE = (params) => post('ConsoleService_Region.asmx/SetRoleUseModule', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'accountWs')

//获取选择平台模块的配置文件
export const GETCONFIGINFO = (params) => get('MasterDataCenter/api/Rescource/GetResourceQuery', params, null, 'webapi')


/*--------------------------------------个人中心start---------------------------------------*/
// 获取个人中心登录信息
export const LOGIN_SESSION_JSON = (params) => post('ANAService.asmx/LoginSession2Json', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')

// 获取用户信息
export const GET_ACCOUNT_INFO = (params) => post('ANAService.asmx/GetAccountInfoJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 用户信息保存
export const SET_ACCOUNT_INFO = (params) => post('AccountService.asmx/SetAccountInfo8User', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 接口保存一下头像地址
export const SET_ACCOUNT_AVATAR = (params) => post('ANAService.asmx/SetAccountInfo8User2Json', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 申请加入学校
export const APPLY_JOIN_SCHOOL = (params) => post('ConsoleService_Region.asmx/AccountApplyJoinSchool', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 撤销加入学校申请
export const REVOKE_APPLY_JOIN_SCHOOL = (params) => post('ConsoleService_Region.asmx/RevokeAccountSchoolCheck', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 获取申请加入学校状态
export const GET_APPLY_STATUS = (params) => post('ConsoleService_Region.asmx/GetAccountSchoolList', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 通过学校编号获取学校信息
export const GET_SCHOOL_BY_NUM = (params) => post('ConsoleService_Region.asmx/GetSchoolByNo', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 获取个人中心信息，新接口（浩杰）
export const NEW_GET_ACCOUNT_INFO = (params) => post('AccountService.asmx/GetAccountInfoBySession', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 个人中心保存信息，新接口（浩杰）
export const NEW_SET_ACCOUNT_INFO = (params) => post('AccountService.asmx/SetAccountTemporaryAttribute', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')
// export const NEW_SET_ACCOUNT_INFO = (params) => post('AccountService.asmx/SetAccountTemporaryAttribute', params, {"Content-Type": "application/json"}, 'accountWs')

// 获取种子信息，与LoginAccountJson等接口配合方便验证登录和修改密码
export const GET_ACCOUNT_SEED = (params) => post('AccountService.asmx/GetAccountSeed', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 验证登录及登录密码，重新加载用户信息
export const LOGIN_ACCOUNT_JSON = (params) => post('AccountService.asmx/LoginAccountJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 验证登录及登录密码，重新加载用户信息(爱读)
export const LOGIN_ACCOUNT_AIDU = (params) => post('AccountService.asmx/LoginAccountAidu', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 设置新密码
export const SET_NEW_PASSWORD = (params) => post('AccountService.asmx/SetNewPassword', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 验证口令是否正确
export const GET_ACCOUNT_SESSION_BY_SECURITYPASSWORD = (params) => post('AccountService.asmx/GetAccountSessionBySecurityPassword', params, {"Content-Type": 'application/x-www-form-urlencoded'}, 'accountWs')


// 班级管理，获取年级信息
export const GET_SCHOOL_PERIOD_GRADE = (params) => post('ANAService.asmx/GetSchoolPeriodGradeJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 设备管理，获取班级信息
export const GET_GRADE_CLASS_LIST = (params) => post('ANAService.asmx/GetGradeClassListJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 获取年级对应的班级信息
export const GET_CLASS_LIST = (params) => post('ANAService.asmx/GetGradeClassListJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 获取选中的年级班级对应的具体班级信息
export const GET_CLASS_INFO = (params) => post('ANAService.asmx/GetClassInfoJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 任教其他班级点击确定时调用
export const SET_CLASS_ACCOUNT = (params) => post('ANAService.asmx/SetClassAccountJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 任教其他班级点击确定时调用
export const SET_CLASS_SUBJECT = (params) => post('ANAService.asmx/SetClassSubjectTeacherJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 更改班级名
export const UPDATE_CLASS = (params) => post('ANAService.asmx/UpdateClassJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 任教其他班级弹窗中获取年级和班级信息
export const GET_GRADE_CLASS = (params) => post('ANAService.asmx/GetClassBySchoolJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 任教其他班级弹窗中获取学科信息
export const GET_SUBJECT = (params) => post('ANAService.asmx/GetSubject2Json', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 获取学生的分组信息
export const GET_STUDENT_LIST = (params) => post('ANAService.asmx/GetClassStudentListJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 新增分组
export const CREATE_CLASS_TEAM = (params) => post('ANAService.asmx/CreateClassTeamJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 除名学生
export const REMOVE_STU = (params) => post('ANAService.asmx/RemoveClassStudentJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 同步班主任分组
export const SYNC_HEADTEACHER_GROUP = (params) => post('ANAService.asmx/SetTeacherCogradientClassTeamJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 学生分组改名
export const SET_CLASS_TEAM = (params) => post('ANAService.asmx/SetClassTeam', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 删除小组
export const REMOVE_CLASS_TEAM = (params) => post('ANAService.asmx/RemoveClassTeamJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 小组学生拖动时更新学生分组
export const SET_STUDENT_TEAM = (params) => post('ANAService.asmx/SetClassTeamStudentJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

/*--------------------班级管理-生活表现：生活组相关接口 start -------------------------------*/
// 获取生活组分类标签信息
export const GET_LIFE_TEAM_TYPE = (params) => post('ANAService.asmx/GetClassLifeTeamType', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 获取生活组内学生分组信息
export const GET_LIFE_TEAM = (params) => post('ANAService.asmx/GetClassLifeTeamStudentList', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 创建生活组信息
export const CREATE_LIFE_TEAM = (params) => post('ANAService.asmx/CreateClassLifeTeam', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 更新生活组信息（比如更新小组名称）
export const UPDATE_LIFE_TEAM = (params) => post('ANAService.asmx/UpdateClassLifeTeam', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 操作学生到生活组（生活组之间相互拖动）
export const OPERATE_LIFE_TEAM = (params) => post('ANAService.asmx/OperateStudentToLifeTeam', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 删除生活组
export const DELETE_LIFE_TEAM = (params) => post('ANAService.asmx/DelClassLifeTeam', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

/*--------------------班级管理-生活表现：生活组相关接口 end -------------------------------*/

/*--------------------设备管理：相关接口 start -------------------------------------------*/
// 获取班级信息
export const GET_CLASS_INFO_JSON = (params) => post('ANAService.asmx/GetClassInfoJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 获取app密码等信息
export const GET_APPPWD_JSON = (params) => post('ANAService.asmx/GetAppPwdJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 开启学生端密码开关
export const APP_TURN_ON_SET_JSON = (params) => post('ANAService.asmx/AppTurnONSetJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 修改终端密码
export const SET_APP_PWD_JSON = (params) => post('ANAService.asmx/SetAppPwdJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 获取终端机型列表
export const GET_BOYD_APPITEM_JSON = (params) => post('ANAService.asmx/GetBoydappitemJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 保存终端系统应用
export const SET_MYCLASS_APPSET_JSON = (params) => post('ANAService.asmx/SetMyClassAppSetJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 获取终端班级应用
export const GET_CLASS_ATTACHMENT_APPSJSON = (params) => post('ANAService.asmx/GetClassAttachmentAppsJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 删除终端班级应用
export const DEL_ATTACHMENT_APPS_CLASS = (params) => post('ANAService.asmx/DelAttachmentAppsClassJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 保存终端班级应用
export const SET_ATTACHMENT_APPS_CLASS_JSON = (params) => post('ANAService.asmx/SetAttachmentAppsClassJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 获取校本应用具体信息
export const GET_APP_INFO = (params) => post('ANAService.asmx/GetAttachmentAppsInfoJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 保存本地上传的应用信息
export const SET_APP_INFO = (params) => post('ResourceService.asmx/SetAttachmentApps', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 对本地上传的app设置共享
export const SET_APP_SHARE = (params) => post('ANAService.asmx/SetAttachmentAppsShareModeJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

// 将应用加入到班级
export const SET_APP_CHOOSE = (params) => post('ANAService.asmx/SetAttachmentAppsChooseJson', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'account')

/*--------------------设备管理：相关接口 end -------------------------------------------*/

/*--------------------任教资历：相关接口 start -------------------------------------------*/
// 任教资历-变化时宏哥用来统计的接口
export const DATA_CHANGE = (params) => postJSON('DataCenterBusiness/api/LenovoTeacherEvaluateService/DataInput', params, {"Content-Type": 'application/json',"session":Vue.prototype.getSession()}, 'webapi') 



/*--------------------任教资历：相关接口 end -------------------------------------------*/




/*--------------------------------------个人中心end---------------------------------------*/

/*------------------------------统一登录 start -------------------------------------------*/
/**
 * @tips 获取验证码 -> 新 
 */
// 获取验证码
export const GET_CAPTCHA = (params) => get('infrastructurecloud/shield/api/v1/Captcha', params, {}, 'webapi', 'blob')

// 校验验证码
export const VALIDATE_CAPTCHA = (params) => get('infrastructurecloud/shield/api/v1/Captcha/Validate', params, {}, 'webapi');

// 发送短信
export const VERIFICATION_SMS = (params, codeId, code) => post('infrastructurecloud/sms/api/v1/VerificationCode', params, {"captcha-id": codeId,"captcha-code": code}, 'webapi');
// 发送邮箱
export const VERIFICATION_EMAIL = (params, codeId, code) => post('infrastructurecloud/email/api/v1/VerificationCode', params, {"captcha-id": codeId,"captcha-code": code}, 'webapi');
// 更换手机
export const SETACCOUNT_PHONE = (params) => post('AccountService.asmx/SetAccountPhone', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'accountWs');
// 更换邮箱
export const SETACCOUNT_EMAIL = (params) => post('AccountService.asmx/SetAccountEMail', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'accountWs');


// 验证数字图片验证码是否正确
export const VALIDATE_NUM_CODE = (params) => post('Account/ValidateRegisterCode', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'accountWs')

// 获取用户登录信息
export const GET_USER_INFO = (params) => post('AccountService.asmx/LoginSessionJson', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')

// 验证口令登录
export const VALIDATE_TOKEN_LOGIN = (params) => post('AccountService.asmx/GetAccountSessionBySecurityPassword', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')

// 短信登录-获取验证码
export const SEND_PHONE_MESSAGE = (params) => postJSON('UserCenter/AccountProfile/SendBindSms', params, {"Content-Type": 'application/json'}, 'webapi') 

// 短信登录-绑定手机号
export const BIND_PHONE_NUMBER = (params) => postJSON('UserCenter/AccountProfile/SetGradeProtectionPhone', params, {"Content-Type": 'application/json'}, 'webapi') 

// 忘记密码-通过邮箱找回
export const GET_BACK_PWD_BY_EMAIL = (params) => post('Account/SendEmail', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs') 

// 忘记密码-验证发送到邮箱的验证码
export const CHECK_EMAIL_CODE = (params) => post('Account/CheckEmailCode', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs') 

// 忘记密码-设置新的密码
export const SET_NEW_PWD = (params) => post('AccountService.asmx/SetNewPassword', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs') 

// 通过域名换平台的code
export const GET_PLATFORM_ID = (params) => post('roleapi/Platform/GetPlatformIdName', params, {"Content-Type": 'application/json, text/plain, */*'}, 'webapi') 

// 获取登录配置
export const GET_LOGIN_CONFIG = (params) => post('MasterDataCenter/api/LoginConfig/GetLoginConfig', params, {"Content-Type": 'application/json, text/plain, */*'}, 'webapi') 

// 获取当前登录账户的角色
// export const GET_ACCOUNT_ROLE = (params) => post('AccountService.asmx/GetAccountRole', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')
export const GET_ACCOUNT_ROLE = (params) => get('AccountService.asmx/GetAccountRoleSpecial', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')

// 选择角色之后将session绑定平台和角色
export const BING_ACCOUNT_SESSION = (params) => post('AccountService.asmx/AccountSessionBindingPlatformAndRole', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')

// 选择角色之后将session绑定平台和角色(新)
export const NEW_BING_ACCOUNT_SESSION = (params) => post('AccountService.asmx/AccountSessionBindingPRZS', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')

// 微信扫码登录成功之后的回调
export const GET_WEIXIN_ACCOUNT = (params) => post('ConsoleService.asmx/GetWeixinAccountBystringUnionId', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')

// 微信首次绑定新的账号
export const WEIXIN_BIND_ACCOUNT = (params) => post('ConsoleService_Region.asmx/WeixinAddAccount', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')

// 联想注册：教师注册（旧）
export const lENOVO_TEACHER_REGISTER = (params) => post('ConsoleService_Region.asmx/AccountRegister', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')

// 联想注册：教师注册（新：包含了手机验证码验证）
export const NEW_lENOVO_TEACHER_REGISTER = (params) => post('ConsoleService_Region.asmx/AccountTeacherRegister', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')

// (新)统一登录：获取种子信息，与LoginAccountJson等接口配合方便验证登录和修改密码，统一登录用的新的接口，与上面的GetAccountSeed不同
export const GET_LOGIN_SEED = (params) => postJSON('UserCenter/Login/GetLoginSeed', params, {"Content-Type": "application/json"}, 'webapi')

// (新)统一登录：账号登录、短信登录和邮箱登录的（账号密码、手机号密码、邮箱密码）都是走这个接口，获取验证码（图片数字、短信）还是走老接口
// 其中参数UnifiedLoginMode参数2是短信登录、3是邮箱验证码登录、4是手机号 邮箱和手机登录如果返回-102015再显示绑定
export const UNIFIED_LOGIN = (params) => postJSON('UserCenter/Login/UnifiedLogin', params, {"Content-Type": 'application/json'},'webapi')

// export const UNIFIED_LOGIN = (params) => postJSON('UserCenter/Login/UnifiedLogin', params, {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}, 'webapi')
// (新)统一登录：向手机短信发送验证码
export const NEW_SHORT_MESSAGE_SEND = (params) => postJSON('UserCenter/ThirdPartyServiceProvide/ShortMessageSend', params, {"Content-Type": "application/json"}, 'webapi')

// (新)统一登录：向邮箱发送验证码
export const NEW_EMAIL_SEND = (params) => postJSON('UserCenter/ThirdPartyServiceProvide/EmailSend', params, {"Content-Type": "application/json"}, 'webapi')

// 统一登录：校验验证码接口，手机短信和邮箱验证码的验证都是走这个接口
export const VALIDATE_CODE = (params) => postJSON('UserCenter/ThirdPartyServiceProvide/ValidateCode', params, {"Content-Type": "application/json"}, 'webapi')

// 原来旧的注册页：标准平台的账号（教师、学生、家长）注册接口
export const CREATE_ACCOUNT = (params) => post('AccountService.asmx/CreateAccountWithRole', params, {"Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'}, 'accountWs')

// 短信登录和邮箱登录如果当前手机号和邮箱没有绑定过账号，则需要先绑定
export const BIND_ACCOUNT = (params) => postJSON('UserCenter/Login/BindAccount', params, {"Content-Type": 'application/json'},'webapi')

// 联想的token换session接口，直接进入选角色的页面
export const AUTHORIZATION_LENOVO = (params) => get('UserCenter/Tripartite/AuthorizationLenovo', params, null,'webapi')


// 找回密码（通过手机号找回）：发送忘记密码短信接口
export const SEND_MESSAGE_OF_FORGET_PWD = (params) => postJSON('UserCenter/Account/ForgotPasswordShortMessageSend', params, {"Content-Type": 'application/json'},'webapi')

// 修改密码接口：通过手机号找回
export const MODIFY_PWD_BY_PHONE = (params) => postJSON('UserCenter/Account/ModifyPasswordByPhone', params, {"Content-Type": 'application/json'},'webapi')

/*------------------------------统一登录 end ---------------------------------------------*/


/*------------------------------联想二维码扫码登录 start ---------------------------------------------*/
export const GET_LENOVO_TICKET = (params) => post('ApiLessonSt/obtaindata/getticket', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'webapi')

export const GET_SCANNINGS_STATUS = (params) => post('ApiLessonSt/obtaindata/getscanningstatus', params, { "Content-Type": 'application/x-www-form-urlencoded', "loading": 'silence'}, 'webapi')

export const WX_BINDING_USER = (params) => post('ConsoleService_Region.asmx/WXBindingUser', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'accountWs')
/*------------------------------联想二维码扫码登录 end ---------------------------------------------*/


/**
 * @params
 * 联想备授课 -> 关联联想教学教研
 */
// 判断账号是否关联
export const AUTHORIZATION_LENOVO_RETURNID = (params) => get('UserCenter/Tripartite/AuthorizationLenovoOrReturnId', params, null, 'webapi')

// 关联账号绑定
export const LENOVO_ACCOUNT_BINDING = (params) => post('UserCenter/Tripartite/LenovoAccounBinding', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'webapi');

// 获取系统创建账号
export const LENOVO_ACCOUNT_REGISTER = (params) => get('UserCenter/Tripartite/LenovoAccounRegister', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'webapi');

// 个人中心关联
export const AUTHORIZATION_LENOVO_NEW = (params) => get('UserCenter/Tripartite/AuthorizationLenovoNew', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'webapi');

/**
 * @params
 * 第三方相关
 */
// 云蝶Code 转 session
export const CLOUDDISK_GET_USERCENTER = (params) => get('UserCenter/Tripartite/AuthorizationKSEdu', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'webapi');

// 中教云通过code获取session
// http://test.fc.eduzibo.com/home/GetZJYAccountSession?code=
export const NECIBOOK_GET_USERCENTER = (params) => get('home/GetZJYAccountSession', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'necibookbApi');
// 通过session获取用户信息
export const Get_Usertoken_By_Session = (params) => get('home/GetUserTokenBySession', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'necibookbApi');

// 没有Code 获取 Encryp
export const GET_ENCRYPTSTRING_KSEDU = (params) => get('UserCenter/Tripartite/GetEncryptStringKSEdu', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'webapi');

// 宁教云通过code获取session
export const NxeDuYun_GET_USERCENTER = (params) => get('RoleAPI/User/GetNJYCallBack', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'nxeduyunbApi');
// 我的组织
export const GET_ACCOUNT_SUBJECT  = (params) => get('RoleAPI/User/GetAccountSubject', params, null, 'webapi');
export const GET_REGIONAL_ACCOUNT_SUBJECT = (params) => get('RoleAPI/User/GetRegionalAccountSubject', params, null, 'webapi');
export const SET_REGIONAL_ACCOUNT_SUBJECT = (params) => post('RoleAPI/User/SetRegionalAccountSubject', params, null, 'webapi');

// 注册精准账号
export const AccountPhoneRegisterByInvitecode = (params) => post('AccountService.asmx/AccountPhoneRegisterByInvitecode', params, { "Content-Type": 'application/x-www-form-urlencoded' }, 'accountWs')