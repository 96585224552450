export default [
  '/Account/SignIn',
  '/Account/LenovoModuleSelect',
  '/Intelligence/installer',
  // 第三方相关
  '/CloudDisk/SignIn',
  '/CloudDisk/Market',
  '/Necibook/SignIn',
  '/Necibook/Auth',
  '/Necibook/Transit',
  '/NxeDuYun/SignIn',
  '/NxeDuYun/Auth',
  '/NxeDuYun/ZznSignIn',
  '/NxeDuYun/ZznAuth',
]